import React, { useState, useEffect } from 'react'
import './Cards.css'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ReactGA from "react-ga4";

import { FaCheck } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { RxCross1 } from "react-icons/rx";

const Cards = () => {

    const [plans, setPlans] = useState([]);
    const navigate = useNavigate();
    const firebaseEASM = 'https://firebasestorage.googleapis.com/v0/b/hackplaza-40bf8.appspot.com/o/Sample-Reports%2FSurface%20Management.pdf?alt=media&token=3e7f7994-00af-4812-8a41-89890d153f4e'

    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
    });

    useEffect(()=>{
        fetchPlans();
    }, [])

    const fetchPlans = async () => {
        try{
            const url = '/v1/plans';
            const res = await axiosInstance.get(url);
            setPlans(res.data);
        }catch(err) {
            // removed log(err);
        }
    }

    const analyticsEvent = async (planName) => {
        ReactGA.event({
            category: "Button",
            action: `${planName} Plan Checkout`
        });
    }

  return (
    <div className='plans-container'>
        <h1 className='plans-header'>Plans & Pricing</h1>
        <p className='plans-subheading'>Try CodeHound today for free, or choose between a selection of pre-configured plans to scale for your needs. For further scaling contact us directly.</p>
        <div className='plans-cards'>
            {
                plans.map((plan) => (
                    <div className={'plans-plan plan-'+plan._id} key={plan._id}>
                        <div className='plans-plan-header'>
                            <h2>{plan.name}</h2>
                            <h1>{plan._id === "63615ab136d8763ae46acd5b" ? plan.price : `£${plan.price}`}<h2>/month</h2></h1>
                            <p>{plan.description}</p>
                        </div>
                        <span />
                        <ul>
                            {
                                plan.features.map((feature, index) => (
                                    <li key={index}><FaCheck className='checkmark-icon' />{feature}</li>
                                ))
                            }
                            {
                                plan.limits.map((feature, index) => (
                                    <li key={index}><RxCross1 className='cross-icon' />{feature}</li>
                                ))
                            }
                        </ul>
                        <button className={plan._id === '635f9f2de3643a9bd03e0a69' ? 'button-closeby' : ''} onClick={() => plan._id === '63615ab136d8763ae46acd5b' || plan._id === '635f9f63e3643a9bd03e0a6d' ? analyticsEvent(plan.name) && navigate('/register') : navigate(`/payment/${plan._id}/pending`)}>{plan._id !== '63615ab136d8763ae46acd5b' && plan._id != '635f9f63e3643a9bd03e0a6d' ? 'Choose Plan' : 'Sign Up'}</button>
                    </div>
                ))
            }
        </div>
    </div>
  )
}

// {
//     plan.features.map((feature, index) => (
//         <li key={index}>{feature}</li>
//     ))
// }

export default Cards