import React, { useState, useEffect } from 'react'
import { Outlet, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const Verify = (props) => {

    const params = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState();
    const [success, setSuccess] = useState(false);

    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
    });

    useEffect((e) => {
        verify(e);
    })
    useEffect(() => {
        if (data){
            if(data.status){
                setSuccess(true);
            }
        }
    }, [data])

    const verify = async (e) => {
        try{
            const request = await axiosInstance.post(`/v1/users/verify/${params.verifyToken}`,{},{headers:{ 'Access-Control-Allow-Origin': (process.env.DEVSTATUS === "Production" ? "https://api.cybersanctus.com" : "*")}});
            setData(request);
        }
        catch(error){
            setSuccess(false);
        }
    }

  return (
    <>
        <div>
            <h1 style={{color: 'white'}}>{success ? navigate('/signin') : "This link has expired."}</h1>
            <Outlet/>
        </div>
    </>
  )
}

export default Verify