import React, { useState, useEffect } from 'react'
import './Dashboard.css'
import Sidebar from './Sidebar/Sidebar'
import Main from './Pages/Main/Main'
import Services from './Pages/Services/Services'
import { useNavigate } from 'react-router-dom'
import { useCookies } from "react-cookie";
import axios from 'axios';

const Dashboard = (props) => {

  const navigate = useNavigate('');
  const [cookies] = useCookies(["cookie-name"]);
  const pathname = window.location.pathname.toLowerCase()

  useEffect(() =>{
    if (!cookies.jwt){
        navigate('/home')
    }
  }, [cookies, navigate])

  return (
    <div className='dashboard-container'>
        <Sidebar userData={props}/>
        {props.routeTo === 0 ? <Main userData={props} />
        : (props.routeTo === 1 ? <Services userData={props} />
        : null)}
        <h1 className='dashboard-disclaimer'>The dashboard is currently unavailable for mobile devices.</h1>
    </div>
  )
}

export default Dashboard