import React, { useState } from 'react';
import './Sidebar.css';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

const Sidebar = (props) => {

    const dashboardIcon = require('./home.svg').default
    const servicesIcon = require('./market.svg').default
    const profileIcon = require('./user.svg').default
    const exitIcon = require('./exit.svg').default
    const cstLogo = require('./logo.png')

    const navigate = useNavigate('');
    const [activeSection, setActiveSection] = useState('main');

  return (
    <div className='sidebar-container'>
        <div className='sidebar-content'>
            <img src={cstLogo} className='sidebar-logo' onClick={() => navigate('/home')}/>
            <div className='sidebar-items'>
                <button className={activeSection === 'main' ? 'active-item' : ''} onClick={() => {setActiveSection('main'); navigate('/dashboard')}}><img src={dashboardIcon} /> Main Dashboard</button>
                <button className={activeSection === 'services' ? 'active-item' : ''} onClick={() => {setActiveSection('main'); toast.warn('This page is under development. ')}}><img src={servicesIcon} />Services Catalogue</button>
                <button className={activeSection === 'profile' ? 'active-item' : ''} onClick={() => {setActiveSection('main'); toast.warn('This page is under development. ')}}><img src={profileIcon} />Profile</button>
                <button className={activeSection === 'exit' ? 'active-item exit-button' : 'exit-button'} onClick={() => {
                    setActiveSection('exit');
                    Cookies.remove('jwt');
                    window.location.pathname = '/signin';
                }}><img src={exitIcon} />Sign Out</button>
            </div>
        </div>
        <div className='sidebar-card'>
            <h2>Have an inquiry?</h2>
            <p>For any inquiries, please email an agent accessible through our customer support email here.</p>
            <button onClick={() => window.open('mailto:info@cybersanctus.com')}>Contact Us!</button>
        </div>
    </div>
  )
}

export default Sidebar