import React from 'react'
import './Terms.css'

const Terms = () => {
  return (
    <div className='terms-container'>
        <div className='terms-content'>
            <h1>Terms of Service</h1>
            <div>
                <h2>Liability for Damages</h2>
                <p>CST NETWORK SECURITY SERVICES LTD. (hereinafter referred to as "CyberSanctus" or "Company") will not be 
                    liable for any damages caused by our penetration testers during the course of a security engagement. 
                    The client understands and agrees that the nature of a penetration testing engagement may involve the 
                    intentional exploitation of vulnerabilities in order to identify and remediate them, and that such 
                    activities may result in temporary or permanent disruptions to the client's systems or network. The 
                    client agrees to hold CyberSanctus harmless from any and all claims, damages, or losses arising from such 
                    disruptions or any other damages that may result from the engagement</p>
            </div>
            <div>
                <h2>Refunds</h2>
                <p>In the event that damages occur as a result of a CyberSanctus engagement, the Company may offer partial or
                    full refunds at its discretion. The client understands that CyberSanctus will make a good faith effort to
                    mitigate any damages that may occur and that any refund offered will be based on the extent to which
                    CyberSanctus was able to do so. The client also understands that no refund will be offered in the event
                    that damages were caused by the client's own actions or inaction.</p>
            </div>
            <div>
                <h2>Timeliness of Reports</h2>
                <p>Monthly reports may not be delivered upon the exact deadline, and CyberSanctus will not be held liable for
                    any missed vulnerabilities as a result. The client understands that the nature of a penetration testing
                    engagement may involve the discovery of previously unknown vulnerabilities, and that the process of
                    identifying and documenting such vulnerabilities may take longer than anticipated. The client also
                    understands that the timeliness of report delivery is not guaranteed and that CyberSanctus will make a
                    good faith effort to deliver reports as soon as possible.</p>
            </div>
            <div>
                <h2>Approval of Requests</h2>
                <p>Not all requests for services from the Company will be approved. The client understands that CyberSanctus
                    reserves the right to decline any request for service that it deems to be outside of its areas of
                    expertise or that may pose a conflict of interest. The client also understands that CyberSanctus may
                    decline a request for service if it determines that the engagement would not be in the best interest
                    of the client or the Company.</p>
            </div>
            <div>
                <h2>Confidentiality of Submitted Documents</h2>
                <p>CyberSanctus will not be held liable for the loss or leakage of any documents submitted by the client.
                    The client is responsible for redacting any sensitive information before submitting documents to
                    the Company. The client also understands that CyberSanctus takes reasonable measures to protect the
                    confidentiality of submitted documents, but that no data storage or transmission method can be
                    completely secure.</p>
            </div>
            <div>
                <h2>Retention of Submitted Documents</h2>
                <p>CyberSanctus will retain submitted documents for a period of 30 days before deletion. The client
                    understands that CyberSanctus may need to retain submitted documents for a limited period of
                    time in order to facilitate the engagement, but that the documents will be deleted in 30
                    days or less after the engagement is complete.</p>
            </div>
            <div>
                <h2>Proof of Ownership/Employment</h2>
                <p>CyberSanctus requires sufficient proof and permission of website ownership or proof of employment
                    to initiate the engagement process. The client understands that it is the client's
                    responsibility to provide adequate proof of ownership or employment before any engagement
                    can begin, and that the engagement will not proceed without such proof.</p>
            </div>
            <div>
                <h2>Governing Law</h2>
                <p>These terms and conditions shall be governed by and construed in accordance with the laws of
                    the United Kingdom. The client agrees that any dispute arising from or related to these
                    terms and conditions will be resolved in accordance with the laws of the United Kingdom.</p>
            </div>
            <div>
                <h2>Entire Agreement</h2>
                <p>These terms and conditions constitute the entire agreement between the parties and supersede
                    all prior agreements or understandings, whether oral or written.</p>
            </div>
            <div>
                <h2>Severability</h2>
                <p>If any provision of these terms and conditions is held to be invalid or unenforceable, such
                    provision shall be struck and the remaining provisions shall be enforced.</p>
            </div>
            <div>
                <h2>Waiver</h2>
                <p>The failure of CyberSanctus to enforce any right or provision in these terms and conditions
                    shall not constitute a waiver of such right or provision unless acknowledged and agreed
                    to by the Company in writing.
                    <br /><br />
                </p>
                <span>By using the services of CST NETWORK SECURITY SERVICES LTD. the client agrees to be bound by these terms and conditions.</span>
            </div>
        </div>
    </div>
  )
}

export default Terms