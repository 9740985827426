import React, { useState, useEffect, useRef } from 'react'
import './Main.css'
import Infobar from '../../Infobar/Infobar'
import { useNavigate } from 'react-router-dom'
import { useCookies } from "react-cookie";
import axios from 'axios';
import { Line, Bar, Pie } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    BarElement,
    ArcElement,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Tooltip,
    Filler
} from 'chart.js';

ChartJS.register(
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Tooltip,
    BarElement,
    ArcElement,
    Filler
)

const Main = (props) => {

    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
    });

    const shieldIcon = require('./shield.svg').default
    const chartIcon = require('./chart.svg').default
    const vulnIcon = require('./bug.svg').default
    const monIcon = require('./pie.svg').default
    const navigate = useNavigate('');
    const [cookies] = useCookies(["cookie-name"]);
    const [orderData, setOrderData] = useState([]);
    const allMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    const thisYear = new Date().getFullYear();
    const thisMonth = new Date().getMonth();
    const currentDate = new Date();
    const yearLabels = [thisYear - 5, thisYear - 4, thisYear - 3, thisYear - 2, thisYear - 1, thisYear];
    const [monthlyPentests, setMonthlyPentests] = useState([0, 0, 0, 0, 0, 0]);
    const [monthlyVulns, setMonthlyVulns] = useState([0, 0, 0, 0, 0, 0]);
    const [vulnBreakdown, setVulnBreakdown] = useState([0, 0, 0]); //4, 12, 16
    const [totalVulns, setTotalVulns] = useState(0);

    const barRef = useRef();

    useEffect((e) => {
        fetchOrders();
    }, [props])

    useEffect(() => {
        const barChart = barRef.current;
        barChart.update();
        barRef.current.update();
    }, [monthlyPentests])

    const getMonthByIndex = (index) => {
        if (index >= 0) {
            return allMonths[index];
          } else {
            // Calculate the positive index equivalent for negative indices
            const positiveIndex = allMonths.length + index;
            return allMonths[positiveIndex];
          }
    }

    const monthLabels = [getMonthByIndex(thisMonth - 5), getMonthByIndex(thisMonth - 4), getMonthByIndex(thisMonth - 3), getMonthByIndex(thisMonth - 2), getMonthByIndex(thisMonth - 1), getMonthByIndex(thisMonth)];

    const appendData = () => {

        let runningVulnsCount = 0;
        const updatedVulnBreakdown = vulnBreakdown;

        for (let i = 0; i < orderData.length; i++) {
            let pentestDate = orderData[i].orderDate
            let pentestMonth = new Date(pentestDate).getMonth();
            const updatedPentestList = monthlyPentests;
            const updatedVulnsList = monthlyVulns;
            const sixMonthsAgo = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
            sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
            const formattedSixMonthsAgo = `${sixMonthsAgo.getFullYear()}-${(sixMonthsAgo.getMonth() + 1).toString().padStart(2, '0')}-${sixMonthsAgo.getDate().toString().padStart(2, '0')}`;

            // Adjust year if the result is negative
            if (currentDate.getMonth() < 6 && sixMonthsAgo.getMonth() > currentDate.getMonth()) {
                sixMonthsAgo.setFullYear(currentDate.getFullYear() - 1);
                sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() + 12);
            }

            const isWithinSixMonths = pentestDate >= formattedSixMonthsAgo;
            for (let j = 0; j < monthLabels.length; j++) {
                if(getMonthByIndex(pentestMonth) === monthLabels[j] && isWithinSixMonths){
                    updatedPentestList[j] = updatedPentestList[j] + 1;
                    updatedVulnsList[j] = orderData[i].vulns.length;
                }
            }

            try{
                runningVulnsCount = runningVulnsCount + orderData[i].vulns.length;
                for (let k = 0; k < orderData[i].vulns.length; k++){
                    // orderData[i].vulns[k]
                    switch (orderData[i].vulns[k].risk) {
                        case 'high':
                            updatedVulnBreakdown[0] = updatedVulnBreakdown[0] += 1
                            break;
                        case 'medium':
                            updatedVulnBreakdown[1] = updatedVulnBreakdown[1] += 1
                            break;
                        case 'low':
                            updatedVulnBreakdown[2] = updatedVulnBreakdown[2] += 1
                            break;
                        default:
                    }
                }
            }
            catch(err){}

            // for (let k = 0; k < orderData[i].vulns.length; k++){
            //     console.log()
            // }

            setMonthlyPentests(updatedPentestList);
        }
        setTotalVulns(runningVulnsCount)
        setVulnBreakdown(updatedVulnBreakdown)
    }

    // const appendData = () => {
    //     for (let i = 0; i < orderData.length; i++) {
    //         let pentestDate = orderData[i].orderDate
    //         let pentestYear = new Date(pentestDate).getFullYear();
    //         console.log(thisMonth);
    //         // console.log(pentestYear)
    //         for (let j = 0; j < yearLabels.length; j++) {
    //             if(pentestYear === yearLabels[j]){
    //                 let updatedList = [...yearlyPentests];
    //                 updatedList[j] = updatedList[j] + 1
    //                 setYearlyPentests(updatedList)
    //             }
    //         }
    //     }
    // }

    const fetchOrders = async (e) => {
        const url = '/v1/tables/me';
        try{
          const orders = await axiosInstance.get(url,{
            headers: {authorization: `Bearer ${cookies.jwt}`}
          });
          setOrderData(orders.data.tables)
          appendData()
          // removed log(orderData)
        }catch(error){
          // removed log(error)
        }
    }

    const vulnData = {
    labels: monthLabels,
    datasets: [{
        data: monthlyVulns,
        backgroundColor: 'transparent',
        borderColor: '#FE107A',
        pointBorderColor: 'transparent',
        pointBorderWidth: 4,
        tension: 0.4,
        borderWidth: 5.2
    }]
    };

    const barData = {
    labels: monthLabels,
    datasets: [{
        data: monthlyPentests,
        backgroundColor: '#FE107A',
        borderColor: 'transparent',
        pointBorderWidth: 4,
        borderWidth: 4,
        fill: true,
        backgroundColor: (context) => {
            const ctx = context.chart.ctx;
            var gradientFill = ctx.createLinearGradient(0, 0, 0, 400);
            gradientFill.addColorStop(0, "#FB9758");
            gradientFill.addColorStop(0.52, "#FE107A");
            return gradientFill;
        },
        borderRadius: 10
    }]
    };

    const pieData = {
    labels: ["high", "medium", "low"],
    datasets: [{
        data: vulnBreakdown,
        backgroundColor: ['#FE107A', '#FB9758', '#f1c9a4'],
        borderColor: 'white',
        pointBorderWidth: 4,
        borderWidth: 2,
        hoverOffset: 40,
        offset: 28
    }]
    };

    const lineOptions = {
    plugins: {
        legend: true,
        tooltip: {
            mode: 'nearest',
            intersect: false
        }
    },
    scales: {
        x: {
            grid: {
                display: false
            },
        },
        y: {
            grid: {
                display: false
            },
            ticks: {
                stepSize: 4,
                display: false
            }
        }
    },
    };

    return (
    <div className='main-container'>

        <Infobar path='Main Dashboard' userData={props.userData} />

        <div className='main-content'>
            <div className='main-cards'>
                <div className='card'>
                    <img src={shieldIcon} />
                    <div>
                        <p className='small-text'>Avg. Score</p>
                        <h2>- -</h2>
                    </div>
                </div>
                <div className='card'>
                    <img src={chartIcon} />
                    <div>
                        <p className='small-text'>Total Pentests</p>
                        <h2>{orderData.length}</h2>
                    </div>
                </div>
                <div className='card'>
                    <img src={vulnIcon} />
                    <div>
                        <p className='small-text'>Vuln. Found</p>
                        <h2>{totalVulns}</h2>
                    </div>
                </div>
                <div className='card'>
                    <img src={monIcon} />
                    <div>
                        <p className='small-text'>Monitoring</p>
                        <h2>N/A</h2>
                    </div>
                </div>
            </div>

            <div className='main-graphs'>
                <div className='line-graph medium-widget'>
                    <div className='line-graph-info'>
                        <div>
                            <p className='small-text'>Vuln. Chart</p>
                            <button className='timer-button'>6 Months</button>
                        </div>
                        <div>
                            <h1>Tot. {totalVulns}</h1>
                            <p className='small-text'>See breakdown</p>
                        </div>
                    </div>
                    <Line data={vulnData} options={lineOptions} className='line-graph-item'></Line>
                </div>
                <div className='alt-graph medium-widget'>
                    <div className='bar-graph'>
                        <div className='bar-graph-info'>
                            <div>
                                <p className='small-text'>Total Pentests</p>
                                <h1>{orderData.length}</h1>
                            </div>
                            <button className='timer-button'>6 Months</button>
                        </div>
                        <Bar data={barData} options={lineOptions} ref={barRef} className='bar-item'></Bar>
                        { monthlyPentests.every((value) => value === 0) ? <p className='small-text no-data'>No data 🔭</p> : null }
                    </div>
                    <div className='pie-graph'>
                        <div className='pie-graph-info'>
                            <p className='small-text'>Vuln. Breakdown</p>
                            <button className='timer-button'>6 Months</button>
                        </div>
                        { vulnBreakdown.every((value) => value === 0) ? <p className='small-text no-data'>No data 🔭</p> : null }
                        <div className='pie-item-container'>
                            <Pie data={pieData} options={lineOptions + {scales: {x: {ticks: {display: false}}}}} className='pie-item' ></Pie>
                        </div>
                    </div>
                </div>
            </div>

            <div className='main-orders'>
            <div className='orders-table medium-widget'>
                    <div className='orders-table-title'>
                        <h2>Unresolved Vuln.</h2>
                        <button>Mark Resolved</button>
                    </div>
                    <table className='orders-table-content'>
                        <thead>
                            <tr className='orders-table-header'>
                                <th>Address</th>
                                <th>Vuln.</th>
                                <th>Order</th>
                                <th>Disc. Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                orderData.map((order) => {
                                    try{
                                        return order.vulns.map((vulnerability) => (
                                            <tr className='orders-table-item' key={vulnerability._id}>
                                                <td><input type="checkbox" />{vulnerability.address}</td>
                                                <td>{vulnerability.vuln}</td>
                                                <td>{order.id}</td>
                                                <td>{vulnerability.disclosure}</td>
                                            </tr>
                                        ))
                                    }catch(err){
                                        
                                    }
                                })
                            }
                        </tbody>
                    </table>
                    
                </div>
                <div className='orders-table medium-widget'>
                    <div className='orders-table-title'>
                        <h2>Orders Table</h2>
                        <button>See Details</button>
                    </div>
                    <table className='orders-table-content'>
                        <thead>
                            <tr className='orders-table-header'>
                                <th>Type</th>
                                <th>Price</th>
                                <th>Order</th>
                                <th>Status</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                orderData.map((order) => (
                                    <tr className='orders-table-item'>
                                        <td>{order.order.planId.name}</td>
                                        <td>${order.order.planId.price}</td>
                                        <td>{order.id}</td>
                                        <td>{order.status}</td>
                                        <td>{order.orderDate}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    
                </div>
            </div>

            <div className='main-footer'>
                <p className='small-text'>© {new Date().getFullYear()} CyberSantus | All Rights Reserved.</p>
            </div>
        </div>

    </div>
    )
}

export default Main