import React from 'react';
import './Contact.css';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import axios from "axios";

const Contact = () => {

    const globeModel = require('./globeModel.png')

    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
    });    

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    // const handleData = (e) => {
    //     toast.success("We'll be in touch with you soon!")
    //     console.log(e)
    // }

    const contactRequest = async (e) => {
        try{
            const url = '/v1/contactus/create';
            const { data } = await axiosInstance.post(
                url,
                {
                    firstName: e.firstName, 
                    // lastName: e.lastName, //NOT REQUIRED
                    company: e.text, //NOT REQUIRED
                    email: e.email,
                    message: e.message //NOT REQUIRED
                },
                {
                    headers: {'Access-Control-Allow-Origin': (process.env.DEVSTATUS === "Production" ? "https://api.cybersanctus.co.uk" : "*")}
                }
            );
            toast.success("We'll be in touch with you soon!")
        }
        catch(error){
			console.log(error)
        }
    }

    const emailPattern =  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    return (
        <div className='contact-container'>
            <img src={globeModel} />
            {/* <span className='span-contact-divider' /> */}
            <video src={require('./CodeHound.mp4')} controls poster={require('./thumbnail.jpeg')} />
            {/* <video src={require('./CodeHound.mp4')} width="440" height="260" controls/> */}
            <form className='contact-form' onSubmit={handleSubmit((data) => contactRequest(data))}>
                <p className='contact-form-sub gradient-text'>CONTACT US</p>
                <h1>Let’s work together</h1>
                <p className='contact-form-subtext'>We're eager to understand the needs of your company. Drop us a message using the form below, and one of our founders will promptly reach out to you.</p>
                <div className='contact-form-elements'>
                    <input {...register('firstName', { required: true })} placeholder='Name' />
                    {/* <input {...register('lastName')} placeholder='Last Name' /> */}
                    <input {...register('text')} placeholder='Company' />
                    <input {...register('email', { pattern: emailPattern, required: true })} placeholder='Email' />
                    <textarea {...register('message')} placeholder='Message' />
                    {errors.firstName ? <p className='error-subtext'>*First name is required.</p> : (errors.email ? <p className='error-subtext'>*Please enter a valid email.</p> : null)}
                    <input type="submit" className='gradient-button contact-submit' />
                </div>
            </form>
        </div>
    )
}

export default Contact