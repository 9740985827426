import React, { useEffect, useRef, useState } from 'react'
import './landing.css'
import { toast } from 'react-toastify';
import Typewriter from 'typewriter-effect';
import axios from "axios";
import { BsStars } from "react-icons/bs";

const Landing = () => {

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });    

  const textRef = useRef(null);
  const letters = "abcdefghijklmnopqrstuvwxyz";
  let interval = null;
  // const [waitlistEmail, setWaitlistEmail] = useState('')
  const [contractAddress, setContractAddress] = useState('')


  const matrixEffect = () => {
    let iteration = 0;
  
    clearInterval(interval);
    
    interval = setInterval(() => {
      textRef.current.innerText =  textRef.current.innerText
        .split("")
        .map((letter, index) => {
          if(index < iteration) {
            return textRef.current.dataset.value[index];
          }
        
          return letters[Math.floor(Math.random() * 26)]
        })
        .join("");
      
      if(iteration >= textRef.current.dataset.value.length){ 
        clearInterval(interval);
      }
      
      iteration += 1 / 3;
    }, 30);
  }

  // const contactRequest = async () => {
  //   try{
  //       const url = '/v1/waitlist/create';
  //       const { data } = await axiosInstance.post(
  //           url,
  //           {
  //               email: waitlistEmail
  //           },
  //           {
  //               headers: {'Access-Control-Allow-Origin': (process.env.DEVSTATUS === "Production" ? "https://api.cybersanctus.co.uk" : "*")}
  //           }
  //       );
  //       toast.success("We'll be in touch with you soon!")
  //   }
  //   catch(error){
  //     toast.error("Something went wrong...")
  //   }
  // }

  const runScan = async (e) => {
    //hidden console.log("[+] SCAN FILE INVOKED.")
    // if (contractAddress){
    //   const formData = new FormData();
    //   formData.append("file", scanZIP);
    //   const link = '/v1/users/scanFile';

    //   try{
    //     toast.info("Scan Started");
    //     setScanLoading(true);
    //     const resp = await axiosInstance({
    //       method: "post",
    //       url: link,
    //       data: formData,
    //       headers: { "Content-Type": "multipart/form-data", authorization: `Bearer ${cookies.jwt}`, "Access-Control-Allow-Origin": (process.env.DEVSTATUS === "Production" ? "https://api.cybersanctus.com" : "*")}
    //     });
    //     toast.success("Scan Sucessful");
    //     setDocUrl(resp.data.scan.docUrl);
    //     setScanLoading(false);
    //   }catch(error){
    //     toast.error("Scan Failed");
    //     setScanLoading(false);
    //     //hidden console.log(error)
    //   }
    // } else{
    //   toast.error("You must upload a ZIP file first!")
    // }
  }

  return (
    <div className='landing2-containter'>
      <div className='landing2-header'>
        <h1>Automating<br/>
          <span data-value="Smart Contract Audits" ref={textRef} onMouseEnter={matrixEffect}>Smart Contract Audits</span><br/>
          With AI <BsStars style={{ color: '#3d59d8', width: '0.8em' }}/>
        </h1>
        <img className='mobile-only' src={require('./chain.png')} style={{width: '80vw'}}/>
        {/* <p>
          Satisfy <span>ISO 27001</span> compliance requirements and meet security evaluations from vendors for web, mobile apps, APIs, and external network infrastructure.
        </p> */}
        <p>
          Introducing <span>CodeHound</span>, our <span>AI enhanced</span> smart contract auditing tool, built to amplify the security of your smart contracts and blockchain projects.
        </p>
      </div>
      <div className='landing2-cta'>
        {/* <input type='text' placeholder='Enter your email' value={waitlistEmail} onChange={event => setWaitlistEmail(event.target.value)} />
        <button className='cta-flex' onClick={() => contactRequest()}>Join Waitlist</button> */}
        <input type='text' placeholder='Smart Contract Address...' value={contractAddress} onChange={event => setContractAddress(event.target.value)} />
        <button className='cta-flex' onClick={() => runScan()}>Run Scan</button>
      </div>
      <div className='landing2-footer'>
        <p>Certified by some of the worlds most innovative companies</p>
        <ul>
            <li><img alt='elearn' src={ require('../../usage/assets/logos/ELS.svg').default } /></li>
            <li><img alt='offensive security' src={ require('../../usage/assets/logos/offsec.svg').default } /></li>
            <li><img alt='Hack The Box' style={{width:'150px'}} src={ require('../../usage/assets/logos/HTB.svg').default } /></li>
            <li><img alt='Certified' src={ require('../../usage/assets/logos/CEH.svg').default } /></li>
        </ul>
      </div>
    </div>
  )
}

export default Landing