import React, { useState, useEffect } from 'react'
import './Blog.css'
import { Footer } from "../../../Importer.js";
import { Navigate, useNavigate } from 'react-router-dom';
import axios from "axios";
import moment from 'moment'

const Blog = () => {

    const searchIcon = require('./search.svg').default
    const cstIcon = require('../../usage/assets/logos/cst_min_white.png')

    const [blogArray, setBlogArray] = useState([]);
    const navigate = useNavigate("");

    useEffect((e) => {
        fetchBlogs(e);
    }, []);

    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
    });

    const fetchBlogs = async (e) => {
        try {
            const url = "/v1/blogs";
            const blogsData = await axiosInstance.get(url);
            setBlogArray(blogsData.data.data.blogs)
        } catch (error) {
            console.log("error");
        }
    };

  return (
    <div className='blog-container'>
        <div className='blog-header'>
            <h2>CyberSanctus</h2>
            <h1>News & Blog</h1>
            <p>Read up on the latest findings, writeups and breakthroughs in the cybersec space.</p>
            <div className='search-input'>
                <img src={searchIcon} />
                <input type="text" placeholder="Search..." />
            </div>
        </div>

        <div className='blog-content'>
            {blogArray.slice(0).reverse()[0] ?
                (
                    <div onClick={() => navigate(`/blog/${blogArray.slice(0).reverse()[0]._id}`)} className='blog-content-maincard blog-content-regular'>
                        <img src={blogArray.slice(0).reverse()[0].photo} />
                        <div className='blog-content-regular-info'>
                            <div className='blog-content-regular-info-tags'>
                            {
                                blogArray.slice(0).reverse()[0].tags.map((tag) => {
                                    return(
                                        <p>{tag}</p>
                                    )
                                })
                            }
                            </div>
                            <h1>{blogArray.slice(0).reverse()[0].title}</h1>
                            <p>{blogArray.slice(0).reverse()[0].description}</p>
                            <div className='blog-content-regular-info-author'>
                                <img src={cstIcon} />
                                <div className='blog-content-regular-info-author-text'>
                                    <p style={{ color: 'white', margin: '0.2em 0', fontSize: '14px' }}>CyberSanctus Team</p>
                                    <p style={{ margin: '0.2em 0', fontSize: '14px' }}>{moment.utc(blogArray.slice(0).reverse()[0].createdAt).local().startOf('seconds').fromNow()}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            : null}
            
            <div className='blog-content-standard-layout'>
                {
                    blogArray.slice(0).reverse().map((blog) => {
                        return(
                            <div onClick={() => navigate(`/blog/${blog._id}`)} className='blog-content-regular'>
                                <img src={blog.photo} />
                                <div className='blog-content-regular-info'>
                                    <div className='blog-content-regular-info-tags'>
                                        {
                                            blog.tags.map((tag) => {
                                                return(
                                                    <p>{tag}</p>
                                                )
                                            })
                                        }
                                    </div>
                                    <h1>{blog.title}</h1>
                                    <p>{blog.description}</p>
                                    <div className='blog-content-regular-info-author'>
                                        <img src={cstIcon} />
                                        <div className='blog-content-regular-info-author-text'>
                                            <p style={{ color: 'white', margin: '0.2em 0', fontSize: '14px' }}>CyberSanctus Team</p>
                                            <p style={{ margin: '0.2em 0', fontSize: '14px' }}>{moment.utc(blog.createdAt).local().startOf('seconds').fromNow()}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>

        <div className='blog-footer'>
            <Footer />
        </div>
    </div>
  )
}

export default Blog