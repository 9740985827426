import React from 'react'
import './Informative.css'

const Informative = () => {

  const platinum = require('./CST_PLATINUM.png');
  const gold = require('./CST_GOLD.png');
  const silver = require('./CST_SILVER.png');
  const bronze = require('./CST_BRONZE.png');

  return (
    <div className='informative-both'>
      <div className='informative-container'>
        <h1>Collect Our Badges</h1>
        <span className='info-additional'>Show off Your Security Achievements</span>
        <div className='informative-cards'>
          <span className='span-separator'/>
          <img src={platinum} />
          <span className='span-separator'/>
          <img src={gold} />
          <span className='span-separator'/>
          <img src={silver} />
          <span className='span-separator'/>
          <img src={bronze} />

          <span className='span-separator'/>
          <img src={platinum} className='desktop' />
          <span className='span-separator desktop'/>
          <img src={gold} className='desktop' />
          <span className='span-separator desktop'/>
          <img src={silver} className='desktop' />
          <span className='span-separator desktop'/>
          <img src={bronze} className='desktop' />
          <span className='span-separator desktop'/>

          <img src={platinum} className='desktop' />
          <span className='span-separator desktop'/>
          <img src={gold} className='desktop'/>

        </div>
      </div>
    </div>
  )
}

export default Informative