import './payment.css';
import { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../../usage/CheckoutForm/CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useCookies } from 'react-cookie';
import { toast, ToastContainer } from 'react-toastify';
import ClipLoader from "react-spinners/ClipLoader";

//CSS properties to be used by react loader
const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
  width: "40px",
  height: "40px"
};

function Payment() {

  const params = useParams();
  const planId = params.planId;
  const status = params.status;
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [loading, setLoading] = useState(false);
  //color for the loader
  let [color, setColor] = useState("#ffffff");

  const [cookies] = useCookies(["cookie-name"]);
  const navigate = useNavigate();

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  const getPaymentSession = async () => {
    const endpoint = `/v1/payments/subscribe/${planId}`
    setLoading(true);
    try{
        const res = await axiosInstance.get(
          endpoint, 
          {headers: { authorization: `Bearer ${cookies.jwt}` }}
        );
        console.log(res.data.session.url)
        window.location.href = res.data.session.url;
        setLoading(false);
    } catch(error){
        console.log(error)
        toast.error("An unexpected error occurred. Please try again later.");
        setLoading(false);
        window.location.pathname = "";
    }

  }

  const getPaymentConfirmation = async () => {
    const endpoint = `/v1/payments/stripe/subscription/${planId}`
    setLoading(true);
    try{
        const res = await axiosInstance.get(endpoint, {
          headers: { authorization: `Bearer ${cookies.jwt}` },
        });
        setLoading(false);
        toast.success("Payment Successful!");
        window.open("https://codehound.cybersanctus.com/");
        window.location.href = "";
    } catch(error){
        console.log(error)
        toast.error("An unexpected error occurred. Please try again later.");
        setLoading(false);
        // window.location.pathname = "";
    }

  }

  const getInstantPaymentSession = async () => {
    const endpoint = `/v1/payments/instant/${planId}`
    setLoading(true);
    try{
        const res = await axiosInstance.get(
          endpoint, 
          {headers: { authorization: `Bearer ${cookies.jwt}` }}
        );
        console.log(res.data.session.url)
        window.location.href = res.data.session.url;
        setLoading(false);
    } catch(error){
        console.log(error)
        toast.error("An unexpected error occurred. Please try again later.");
        setLoading(false);
        window.location.pathname = "";
    }

  }

  const getInstantPaymentConfirmation = async () => {
    const endpoint = `/v1/payments/stripe/instant/${planId}`
    setLoading(true);
    try{
        const res = await axiosInstance.get(endpoint, {
          headers: { authorization: `Bearer ${cookies.jwt}` },
        });
        setLoading(false);
        toast.success("Payment Successful!");
        window.open("https://codehound.cybersanctus.com/");
        window.location.href = "";
    } catch(error){
        console.log(error)
        toast.error("An unexpected error occurred. Please try again later.");
        setLoading(false);
        // window.location.pathname = "";
    }

  }

  // const stripePromise = loadStripe(process.env.STRIPE_PUB_KEY);

    useEffect(() =>{
        if (!cookies.jwt){
            toast.error('Please login or create account first to buy plan')
            navigate('/register')
        }
    }, [cookies, navigate])

  // useEffect(() => {
  //   setLoading(true);
  //   axiosInstance.get("/v1/payments/config", {
  //     headers: {
  //       authorization: `Bearer ${cookies.jwt}`,
  //       "Access-Control-Allow-Origin": (process.env.DEVSTATUS === "Production" ? "https://api.cybersanctus.com" : "*")
  //     }
  //   }).then(r => {
  //     console.log("API CALL TO CONFIG ", r)
  //     const { publishableKey } = r.data
  //     setStripePromise(loadStripe(publishableKey));
  //     // setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY));
  //     console.log("SET STRIPE PROMISE TO: ", stripePromise);
  //     setLoading(false);
  //   });
  // }, []);

  useEffect(() => {
    console.log(planId);
    console.log(status);
    if(status === "pending"){
      getPaymentSession();
    } else if (status === "completed"){
      getPaymentConfirmation();
    } else if (status === "instant-pending"){
      getInstantPaymentSession();
    } else if (status === "instant-completed"){
      getInstantPaymentConfirmation();
    }
    // if(status != "completed"){
    //   getPaymentSession();
    // } else{
    //   getPaymentConfirmation();
    // }
  }, [])

  const appearance = {
    theme: 'night'
  };

  return (
    <div className='payment-container'>
      <ToastContainer limit={1} />
      <img alt='logo' style={{width: '400px', maxWidth: '20em'}} src={require('../../usage/assets/logos/cst_full_white.png')} />
      {status === "completed" | status === "instant-completed" ? <h1 style={{color: 'white'}}>Checking Your Payment Status...</h1> : <h1 style={{color: 'white'}}>Redirecting You To The Payment Page...</h1>}
      {/* <h1 style={{color: 'white'}}>Redirecting You To The Payment Page...</h1> */}
      {
        loading ? 
        <ClipLoader
          color={color}
          loading={loading}
          cssOverride={override}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      : null
      }
      {/* {
        loading ? 
        <ClipLoader
          color={color}
          loading={loading}
          cssOverride={override}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      :(<>
          {(
            <Elements stripe={stripePromise} >
              <CheckoutForm planId={planId} />
            </Elements>
          )}
        </>)
      } */}
      
    </div>
  );
}

export default Payment;
