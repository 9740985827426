import React from 'react'
import './Description.css'

import { IoCodeSlash } from "react-icons/io5";
import { VscVscode } from "react-icons/vsc";
import { IoGitNetworkOutline } from "react-icons/io5";
import { LuScanLine } from "react-icons/lu";
import { MdOutlineArrowOutward } from "react-icons/md";


const description = () => {

  const group1 = require('./group1.png');
  const group2 = require('./group2.png');
  const group3 = require('./group3.png');
  const group4 = require('./group4.png');
  const code = require('./code.svg').default;

  return (
    <div className='description-container'>
      <div className='description-block'>
        <div className='line-span mobile'>
          <div className='line-span-blob'></div>
          <div className='line-span-blur'></div>
          <LuScanLine className='line-span-icon'/>
          <div className='line-span-straight'></div>
        </div>
        <div className='description-block-content'>
          <img src={group1} />
          <div className='line-span desktop'>
            <div className='line-span-blob'></div>
            <div className='line-span-blur'></div>
            <LuScanLine className='line-span-icon'/>
            {/* <div className='line-span-icon' /> */}
            <span className='line-span-straight'></span>
          </div>
          <div className='description-content'>
            <h1>AI-Powered Smart Contract Audits</h1>
            <p className='sub-text'>CodeHound leverages the outstanding work done by the OpenAI team and integrates GTP-4o's advanced AI technology with over 200 billion parameters to deliver reliable results. Whether you’re working on a single Solidity file or an entire Hardhat or Truffle project, our fine-tuned model works in conjunction with our current static and dynamic scanners to perform deep analysis to detect vulnerabilities and highlight security risks.
            </p>
            <p className='sub-text'> Our focus is on automating the tedious parts of the security audit process, allowing developers to spend more time innovating and less time worrying about code security. Once the audit is complete, you’ll receive a detailed PDF report outlining all findings, vulnerabilities, and recommended fixes.</p>
            <button onClick={() => window.open("https://docs.cybersanctus.com/")}>Learn More <MdOutlineArrowOutward /></button>
          </div>
        </div>
      </div>

      <div className='description-block'>
        <div className='line-span mobile'>
          <div className='line-span-blob'></div>
          <div className='line-span-blur'></div>
          <IoCodeSlash className='line-span-icon'/>
          <div className='line-span-straight'></div>
        </div>
        <div className='description-block-content description-block-content-reverse'>
          <img src={group2} />
          <div className='line-span desktop'>
            <div className='line-span-blob'></div>
            <div className='line-span-blur'></div>
            <IoCodeSlash className='line-span-icon'/>
            {/* <div className='line-span-icon' /> */}
            <span className='line-span-straight'></span>
          </div>
          <div className='description-content'>
            <h1>Using Trusted and Reliable Vulnerability Detectors</h1>
            <p className='sub-text'>CodeHound incorporates hundreds of vulnerability detectors spread between already trusted static and dynamic scanners alongside our fine-tuned AI analysis to ensure a thorough and reliable review of your smart contracts. We scan for well-known vulnerabilities such as reentrancy, integer overflows, and gas-related issues, ensuring your code meets the highest security standards.
            </p>
            <p className='sub-text'>Our detectors are built upon widely recognized frameworks and are continuously updated. Whether you're developing DeFi protocols or decentralized applications, you can trust that CodeHound will uncover potential risks and provide actionable steps in just a few minutes.</p>
            <button onClick={() => window.open("https://docs.cybersanctus.com/")}>Learn More <MdOutlineArrowOutward /></button>
          </div>
        </div>
      </div>

      <div className='description-block'>
        <div className='line-span mobile'>
          <div className='line-span-blob'></div>
          <div className='line-span-blur'></div>
          <VscVscode className='line-span-icon'/>
          <div className='line-span-straight'></div>
        </div>
        <div className='description-block-content'>
          <img src={group3} />
          <div className='line-span desktop'>
            <div className='line-span-blob'></div>
            <div className='line-span-blur'></div>
            <VscVscode className='line-span-icon'/>
            {/* <div className='line-span-icon' /> */}
            <span className='line-span-straight'></span>
          </div>
          <div className='description-content'>
            <h1>Download our VSCode Extension</h1>
            <p className='sub-text'>Take your Solidity development experience to the next level with the CodeHound VSCode extension. Analyze smart contracts and receive security feedback without ever leaving your IDE. With just a few clicks, you can initiate audits, check for vulnerabilities, and access detailed reports, all from within the familiar VSCode interface.</p>
            <p className='sub-text'>We strive to help developers maintain a seamless workflow while ensuring their smart contracts are secure. Download the VSCode extension today and start securing your smart contracts with ease.</p>
            {/* <button>Download Now <MdOutlineArrowOutward /></button> */}
            <button>Coming Soon</button>
          </div>
        </div>
      </div>

      <div className='description-block'>
        <div className='line-span mobile'>
          <div className='line-span-blob'></div>
          <div className='line-span-blur'></div>
          <IoGitNetworkOutline className='line-span-icon'/>
          <div className='line-span-straight'></div>
        </div>
        <div className='description-block-content description-block-content-reverse'>
          <img src={group4} />
          <div className='line-span desktop'>
            <div className='line-span-blob'></div>
            <div className='line-span-blur'></div>
            <IoGitNetworkOutline className='line-span-icon'/>
            {/* <div className='line-span-icon' /> */}
            <span className='line-span-straight'></span>
          </div>
          <div className='description-content'>
            <h1>Introducing GitHub Integration</h1>
            <p className='sub-text'>We've just made it easier than ever to scan your Solidity code directly from your repositories. You can now connect your GitHub account to the CodeHound dashboard, select your repositories, and let our tool automatically fetch and analyze the code.</p>
            <p className='sub-text'>No more manually downloading files or worrying about missing code updates. Our GitHub integration allows you to scan your latest commits for security risks and track vulnerabilities across your projects with ease.</p>
            {/* <button>Connect Account <MdOutlineArrowOutward /></button> */}
            <button onClick={() => window.open("https://docs.cybersanctus.com/")}>Learn More <MdOutlineArrowOutward /></button>
          </div>
        </div>
      </div>
      
    </div>
  )
}

export default description