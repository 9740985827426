import React, { useState, useEffect } from 'react'
import './navbar.css'
import { useCookies } from "react-cookie";
import { Navigate, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import {Twirl as Hamburger} from 'hamburger-react';
import scrollListener from '../../hooks/scrollListener';
import { MdArrowOutward } from "react-icons/md";

const Navbar = (props) => {

    const [collapsed, setCollapsed] = useState(true);
    const [loggedIn, setLoggedIn] = useState(false);
    const [userProfile, setUserProfile] = useState(require('../assets/icons/user.svg').default);
    const [activeLink, setActiveLink] = useState('');
    const [inView, setInView] = useState(true);
    const scroll = scrollListener();

    const [cookies] = useCookies(["cookie-name"]);
    const navigate = useNavigate('');

    useEffect(() => {
        changeBackground()
        if (props.user){
            //console.log(props.user)
            if(props.user.data.user.profilePic){
                setUserProfile(props.user.data.user.profilePic);
            }
        }
    }, [props])

    useEffect(() =>{
        if (cookies.jwt){
            setLoggedIn(true);
        }
        if (window.location.pathname === '/' | window.location.pathname === '/home'){
            setActiveLink('Home')
        }else if (window.location.pathname === '/plans'){
            setActiveLink('Booking')
        }else if (window.location.pathname === '/documentation'){
            setActiveLink('Documentation')
        }else if (window.location.pathname === '/blog'){
            setActiveLink('Blog')
        }
    }, [cookies, navigate])

    const changeBackground = () => {
        if(window.scrollY > 0 && window.scrollY - scroll.lastY >= 0){
            setInView(true);
        }
        else {
            setInView(false);
        }
    }

    window.addEventListener('scroll', changeBackground)

  return (
    <div className={inView ? 'navbar-container navbar-hidden' : 'navbar-container'}>
        <nav className={collapsed ? '' : 'nav-expanded'}>
            <ul className='navbar-navigation'>
                <img src={require('../../usage/assets/logos/cst_half_white.png')} onClick={() => navigate('/')} />
                <li style={activeLink === 'Home' ? {color: '#FE107A'} : {}}><a href='/'>Home</a></li>
                <li style={activeLink === 'Documentation' ? {color: '#FE107A'} : {}}><a href='https://docs.cybersanctus.com'>Docs</a></li>
                <li style={activeLink === 'Blog' ? {color: '#FE107A'} : {}}><a href='/blog'>Blogs</a></li>
                {/* <li style={activeLink === 'Booking' ? {color: '#FE107A'} : {}}><a href='/plans'>Booking</a></li> */}
                <li style={activeLink === 'Booking' ? {color: '#FE107A'} : {}}><a href='https://docs.cybersanctus.com/category/internal-audits'>Services</a></li>
                {/* <li ><a href='https://docs.cybersanctus.com/category/internal-audits'>Services</a></li> */}
                {/* https://docs.cybersanctus.com/category/internal-audits */}
            </ul>
            <ul className='navbar-status'>
                <div className='navbar-actions'>
                    {
                        loggedIn ? (
                            <>
                                <img src={userProfile} />
                                <button onClick={() => window.open("https://codehound.cybersanctus.com")}>Open CodeHound <MdArrowOutward /></button>
                            </>
                        ):(
                            <>
                                <li><a href='/signin'>Sign in</a></li>
                                <button onClick={() => navigate('/register')}>Register</button>
                            </>
                        )
                    }
                    {/* <li><a href='/signin'>Sign in</a></li>
                    <button>Register</button> */}
                </div>
                <button className='menu-btn' onClick={() => {setCollapsed(!collapsed)}}><Hamburger direction='left' size={24} color='linear-gradient(90deg, #FE107A 0%, #FB9758 100%)' /></button>
            </ul>
        </nav>
    </div>
  )
}

export default Navbar