import React from 'react'
import './Footer.css'
import { Navigate, useNavigate } from 'react-router-dom'

import { FaDiscord } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { PiInstagramLogoFill } from "react-icons/pi";
import { MdArrowOutward } from "react-icons/md";

const Footer = () => {

    const navigate = useNavigate('');

  return (
    <div className='footer-container'>
        <div className='footer-all'>
            <div className='footer-header'>
                <img src={require('../../usage/assets/logos/cst_half_white.png')} />
                <p>Leading the cybersecurity industry, powering the decline in cyber attacks</p>
            </div>
            <div className='footer-content'>
                <div className='footer-section'>
                    <div>
                        <h2>Support</h2>
                        <p onClick={() => navigate('/plans')}><MdArrowOutward className='footer-link-arrow'/>Pricing Plan</p>
                        <p onClick={() => window.open('https:/docs.cybersanctus.com')}><MdArrowOutward className='footer-link-arrow'/>Documentation</p>
                        <p onClick={() => window.open('https://docs.cybersanctus.com/category/internal-audits')}><MdArrowOutward className='footer-link-arrow'/>Services</p>
                    </div>
                    <div>
                        <h2>Legal</h2>
                        <p onClick={() => navigate('/terms-of-service')}><MdArrowOutward className='footer-link-arrow'/>Terms</p>
                        <p onClick={() => navigate('/terms-of-service')}><MdArrowOutward className='footer-link-arrow'/>Privacy</p>
                        <p onClick={() => window.open('mailto:info@cybersanctus.com')}><MdArrowOutward className='footer-link-arrow'/>Claim</p>
                    </div>
                </div>
                <div className='footer-banner'>
                    <h2>Contact Us</h2>
                    <p>info@cybersanctus.com</p>
                    <div className='footer-banner-icons'>
                        <FaDiscord className='banner-icon banner-icon-first'/>
                        <FaTelegramPlane className='banner-icon'/>
                        <FaLinkedinIn className='banner-icon' onClick={() => window.open('https://www.linkedin.com/company/hackplaza/')}/>
                        <PiInstagramLogoFill className='banner-icon' onClick={() => window.open('https://www.instagram.com/cybersanctus')}/>
                    </div>
                </div>
                {/* <div className='footer-section'>
                    <div>
                        <h2 onClick={() => window.open('mailto:info@cybersanctus.com')}>Legal</h2>
                        <p onClick={() => window.open('mailto:info@cybersanctus.com')}>Claim</p>
                        <p onClick={() => navigate('/terms-of-service')}>Privacy</p>
                        <p onClick={() => navigate('/terms-of-service')}>Terms</p>
                    </div>
                    <div>
                        <h2>Contact Us</h2>
                        <p onClick={() => window.open('mailto:info@cybersanctus.com')}>info@cybersanctus.com</p>
                    </div>
                </div> */}
            </div>
        </div>
        <div className='footer-legal'>
            <p>© {new Date().getFullYear()} - All rights reserved by CyberSanctus</p>
        </div>
    </div>
  )
}

export default Footer