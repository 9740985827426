import React from 'react';
import './Services.css';
import Infobar from '../../Infobar/Infobar';

const Services = (props) => {
  return (
    <div className='services-container'>
        <Infobar path='Services Catalogue' userData={props.userData} />
        <div className='services-content'>
            <div className='medium-widget'></div>
        </div>
    </div>
  )
}

export default Services