import React from 'react'
import './Promotional.css'
import { Navigate, useNavigate } from 'react-router-dom';

const Promotional = () => {

    const mobilePreview = require('./mobile-preview.png');
    const desktopPreview = require('./desktop-preview.png');

    const navigate = useNavigate("");

  return (
    <div className='promotional-container'>
        <div className='promotional-content'>
            <p className='promotional-subheading'>CODEHOUND DASHBOARD</p>
            <h1>Take me to CodeHound</h1>
            <p>Click the button below to be redirected to your CodeHound Dashboard, where you can manage your projects, view analytics, and access all your development tools.</p>
            <button onClick={() => navigate('/register')}>Sign up For Free Trial</button>
        </div>
        {/* <span className='promotional-spacer' /> */}
        <img className='mobile' src={mobilePreview} />
        <img className='desktop' src={desktopPreview} />
    </div>
  )
}

export default Promotional