import React, { useState, useEffect } from 'react'
import Navbar from '../../usage/Navbar/Navbar.jsx';
import '../Register/Register.css'
import OAuth2Login from 'react-simple-oauth2-login';
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import { Outlet, useNavigate } from 'react-router-dom';
import { useCookies } from "react-cookie";
import { toast } from 'react-toastify';
import { getErrorMessage } from '../../../utils/getError';
import { decodeToken } from '../../../utils/decodeToken';
import axios from 'axios';

const Forgot = () => {

    const [email, setEmail] = useState('');
    const [data, setData] = useState();
    const [success, setSuccess] = useState(false);
    const [submit, setSubmit] = useState(false);

    const navigate = useNavigate('');
    const [cookies, setCookie, removeCookie] = useCookies(["cookie-name"]);

    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
    });

    const resetPassword = async (e) => {
        e.preventDefault();
        const url = "/v1/users/forget-password";
        try{
            const request = await axiosInstance.post(url, {
                email
            })
            setData(request);
            toast.success('Email Sent!')
        }catch(error){
            toast.error('An error occured...')
        }
    }

    const onSuccessFacebook = async (res) => {
        try{
            const {access_token} = res;
            const response = await axiosInstance.post('/v1/users/facebook',
            {
                access_token
            },
            {
                headers: {'Access-Control-Allow-Origin': (process.env.DEVSTATUS === "Production" ? "https://api.cybersanctus.com" : "*")}
            }
            );
            setCookie("jwt", response.data.data.token);
            navigate('/home');
        }catch(err) {
            toast.error('An unexpected error occured, please try again later.');
        }
    }

    const onFailure = (res) => {
        toast.error(getErrorMessage(res));
    }

    const googleLogin = useGoogleLogin({
        onSuccess: async ({ code }) => {
            try{
            const tokens = await axiosInstance.post('/v1/users/google/tokens', {
                code,
            });
        
            const decoded = decodeToken(tokens.data.id_token);
            const response = await axiosInstance.post('/v1/users/google', {
                email: decoded.email,
                password: decoded.sub,
                profilePic: decoded.picture
            })
            setCookie("jwt", response.data.data.token);
            //console.log(response)
            navigate('/home')
            }catch(err) {
            toast.error(getErrorMessage(err));
            }
            
        },
        onError: () => {
            toast.error("Login failed")
        },
        flow: 'auth-code',
    })

  return (
    <div className='register-container'>
        <div className='register-content'>
            <div className='register-header'>
                <h1>Reset Password</h1>
                <p>Hello there! Create an account now to kickstart your cybersecurity journey with us.</p>
                <div>
                    <button><img id='mobile-cta' src={ require('../../usage/assets/logos/apple.svg').default } alt="Apple Login" /></button>
                    <button onClick={googleLogin} id='googleBtn' className='active-login'><img id='mobile-cta' src={ require('../../usage/assets/logos/google.svg').default } alt="Google Login" /></button>
                    <OAuth2Login
                        authorizationUrl="https://www.facebook.com/dialog/oauth"
                        responseType="token"
                        clientId="1020616116007319"
                        redirectUri="https://cybersanctus.com/oauth-callback"
                        onSuccess={onSuccessFacebook}
                        onFailure={onFailure}
                        render={renderProps => (
                            <button style={{cursor: 'pointer'}} onClick={renderProps.onClick} disabled={renderProps.disabled} className=''><img id='mobile-cta' src={ require('../../usage/assets/logos/facebook.svg').default } alt="Facebook Login" /></button>
                        )}
                        scope={'email'}
                        >
                                            
                    </OAuth2Login>
                </div>
            </div>
            <div className='span-separator'>
                <span></span>
                <p>or</p>
                <span></span>
            </div>
            <div className='register-fields'>
                <div className='register-field'>
                    <div>
                        <label><img src={require('./email.svg').default} />Enter Email</label>
                    </div>
                    <input type='email' placeholder='example@cybersanctus.com' value={email} onChange={event => setEmail(event.target.value)} />
                </div>
            </div>
            <div className='register-actions'>
                <button className='register-button' onClick={(e) => resetPassword(e)}>Send Link</button>
                <p className='register-offer'>Don't have an account? <span onClick={() => navigate('/signin')} style={{cursor: 'pointer'}}>Sign in</span></p>
            </div>
        </div>
    </div>
  )
}

export default Forgot